import React from 'react';
import './App.css';

const Home = () => {
  return (
    <div>
      <div className="title">
        <h1>Hello World</h1>
        <p>Hosted by Eliot himself!</p>
      </div>
      <div className="wrapper">
        <p>This is now my simplified home text.</p>
      </div>
    </div>
  );
}

export default Home;